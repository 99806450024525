<template>
  <div id='container-menu' :class="{ 'menu-hide' : menuHide}">
    <div class="w-100">
      <b-nav vertical class="w-100">
        <b-nav-item @click="colapseProduct" v-b-toggle.prod>
          <i :class="productManagementIcon"></i> Publicidade
          <i class="fas fa-chevron-left float-right when-closed"></i>
          <b-collapse id="prod">
            <ul class="mt-2 dropdown">
              <li>
                <b-link
                  class="d-block"
                  :to="{ name: 'contracts' }">
                  Contratos
                </b-link>
              </li>
              <li>
                <b-link
                    class="d-block"
                    :to="{ name: 'highlights' }">
                  Destaques
                </b-link>
              </li>
              <li>
                <b-link
                    class="d-block"
                    :to="{ name: 'banners' }">
                  Banners
                </b-link>
              </li>
            </ul>
          </b-collapse>
        </b-nav-item>
        <b-nav-item>
          <b-link
              class="d-block"
              :to="{ name: 'ensure-quality' }">
            Validação de qualidade
          </b-link>
        </b-nav-item>
        <b-nav-item>
          <b-link
              class="d-block"
              :to="{ name: 'digital-fairs' }">
            Feiras Digitais
          </b-link>
        </b-nav-item>
        <b-nav-item>
          <b-link
              class="d-block"
              :to="{ name: 'importer-logs' }">
            Logs de Importação
          </b-link>
        </b-nav-item>
        <b-nav-item>
          <b-link
              class="d-block"
              :to="{ name: 'client-errors' }">
            Clientes com erros
          </b-link>
        </b-nav-item>
      </b-nav>
    </div>
  </div>
</template>

<style lang="css" scoped>
  #container-menu{
    background: #fff;
    position: fixed;
    top: 54px;
    bottom: 0;
    left: 0;
    right: 0;
    width: 270px;
    transition: left ease 0.5s;
  }

  #container-menu ul li a{
    color: #5A8DEE;
  }

  ul li{
    list-style: none;
  }

  #container-menu ul li a{
    padding-left: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
    transition: all ease 0.5s;
  }

  #container-menu ul li a:hover{
    padding-left: 20px;
    transition: all ease 0.5s;
  }

  #container-menu ul li a i{
    width: 32px;
    text-align: center;
    line-height: 24px
  }

  .router-link-active{
      background: whitesmoke;
  }

  .menu-hide{
    left: -320px !important;
    transition: all ease 0.5s;
    transition-delay: 0.5s;
  }
</style>

<script>
export default {
  props: ['menuHide'],
  data() {
    return {
      boxOpen: false,
      productManagementIcon: 'fas fa-box',
      active: false,
    };
  },
  methods: {
    showAndHide() {
      this.active = !this.active;
    },
    handleLogout() {
      this.$auth.logout();
    },
    colapseProduct() {
      this.boxOpen = !this.boxOpen;
      this.productManagementIcon = this.boxOpen ? 'fas fa-box-open' : 'fas fa-box';
    },
  },
  computed: {
    user() {
      if (localStorage.getItem('user')) return JSON.parse(localStorage.getItem('user'));
      return { picture: '' };
    },
  },
};
</script>
